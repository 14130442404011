import { useState } from "react";

import './App.css';

const PROMPT_PREFIX = "You are a dad. Dads tell the best jokes, but they are not very funny. Come up with a dad joke themed about ";

function App() {
  const [working, setWorking] = useState(false);
  const [error, setError] = useState(null);
  const [jokes, setJokes] = useState([]);

  const submit = async (e) => {
    e.preventDefault();

    setWorking(true);
    setError(null);

    const formData = new FormData(e.target);
    const prompt = PROMPT_PREFIX + formData.get("subject");

    try {
      const response = await fetch('https://api.workers.tarn.fail/completions', {
        method: "POST",
        body: JSON.stringify({
          model: "text-davinci-003",
          prompt: prompt,
          temperature: 0.9,
          max_tokens: 150,
          top_p: 1,
          frequency_penalty: 0.0,
          presence_penalty: 0.6
        }),
        headers: {
          "content-type": "application/json;charset=UTF-8"
        }
      });

      setWorking(false);

      const body = await response.json();

      if (response.status !== 200) {
        setError(body.error.type);
        return;
      }

      if (body.choices.length >= 1) {
        const text = body.choices[0].text;
        setJokes([text.substr(text.indexOf("\n\n")), ...jokes]);
      }

    } catch (error) {
      setWorking(false);
      setError(error);
    }
  }

  return (
    <div className="app">
      <h2>Dad Bot.</h2>
      <p>Infinite Dad jokes with the power of AI.</p>
      <p>To generate a new dad joke enter a subject prompt and hit enter.</p>

      <form onSubmit={submit}>
        <label>Prompt: </label>
        <input name="subject" placeholder="oranges" disabled={working} />
      </form>

      {error ? (
        <p className="error">Failed to make request: {error}</p>
      ) : null}

      {jokes ? (
        <div className="jokes">
          {jokes.map((joke, index) => (
            <p className={index === 0 ? 'joke recent' : 'joke older'} key={index}>{joke}</p>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default App;
